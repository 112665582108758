import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'

import randomColor from 'randomcolor'

function invertColor(hexTripletColor) {
    var color = hexTripletColor;
    color = color.substring(1); // remove #
    color = parseInt(color, 16); // convert to integer
    color = 0xEEEEEE ^ color; // invert three bytes
    color = color.toString(16); // convert to hex
    color = ("000000" + color).slice(-6); // pad with leading zeros
    color = "#" + color; // prepend #
    
    return color;
}

/**
 * Base
 */
// Debug
const gui = new GUI({title: 'Click here to have fun!'})
gui.show(true)
gui.close()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
//const axesHelper = new THREE.AxesHelper(minSceneSphereRadius)
//scene.add(axesHelper)

// // Lights
const light = new THREE.AmbientLight(0xFFFFFF, 0.6)
scene.add(light)

const firstLight = new THREE.PointLight(0xFFFFFF, 40)
firstLight.position.x = 0
firstLight.position.y = 3
firstLight.position.z = 0
scene.add(firstLight) 

const secondLight = new THREE.PointLight(0xFFFFFF, 40)
secondLight.position.x = 0
secondLight.position.y = 0
secondLight.position.z = 4
scene.add(secondLight) 

const thirdLight = new THREE.PointLight(0xFFFFFF, 40)
thirdLight.position.x = 5
thirdLight.position.y = 0
thirdLight.position.z = 0
scene.add(thirdLight) 

// Configuration
const configOptions = {
    minSceneSphereRadius: 3,
    maxSceneSphereRadius: 16,
    objectsCount: 1000,
    sceneBackgroundColor: "#9DE7CA",
    nameColor: "#e2653c",
}

let localObjectCount = configOptions.objectsCount

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const nameMaterial = new THREE.MeshPhysicalMaterial({color: configOptions.nameColor})
nameMaterial.metalness = 0
nameMaterial.roughness = 1

const silverMatcapTexture = textureLoader.load('textures/matcaps/3.png')
silverMatcapTexture.colorSpace = THREE.SRGBColorSpace
const silverMaterial = new THREE.MeshMatcapMaterial()
//material.wireframe = true
silverMaterial.matcap = silverMatcapTexture

const wireframeMatcapTexture = textureLoader.load('textures/matcaps/3.png')
wireframeMatcapTexture.colorSpace = THREE.SRGBColorSpace
const wireframeMaterial = new THREE.MeshMatcapMaterial()
wireframeMaterial.wireframe = true
wireframeMaterial.matcap = wireframeMatcapTexture

/**
 * Objects
 */


// Loaders
const fontLoader = new FontLoader()
let name;
fontLoader.load('fonts/helvetiker_regular.typeface.json', 
(font) => {
    
    const nameGeometry = new TextGeometry('Niko Zarzani',{
        font: font,
        size: 0.5,
        height: 0.2,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 5
    })

    name = new THREE.Mesh(
        nameGeometry,
        nameMaterial
    )
    // textGeometry.computeBoundingBox()
    // text.position.x = -(textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x)/2
    // text.position.y = -(textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y)/2
    // text.position.z = -(textGeometry.boundingBox.max.z - textGeometry.boundingBox.min.z)/2

    nameGeometry.center()
    scene.add(name)

    const subtitleGeometry = new TextGeometry('(work in progress...)',{
        font: font,
        size: 0.2,
        height: 0.1,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.01,
        bevelSize: 0.01,
        bevelOffset: 0,
        bevelSegments: 5
    })

    const subtitle = new THREE.Mesh(
        subtitleGeometry,
        silverMaterial
    )
    subtitleGeometry.center()
    subtitle.position.y = - 0.6
    
    scene.add(subtitle)
})

const donutGeometry = new THREE.TorusGeometry(0.2, 0.07, 10, 45)
const sphereGeometry = new THREE.SphereGeometry(0.2, 20, 45)
const squareGeometry = new THREE.BoxGeometry(0.4, 0.4, 0.4)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.001, 1000000)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 6
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


let objectsArray = []

function compute3DCoordinates(minSphereRadius, maxSphereRadius) {

    if(minSphereRadius>=maxSphereRadius){
        return null
    }

    const randomValueX = (Math.random() - 0.5) * 2 * (maxSphereRadius )
    const randomValueY = (Math.random() - 0.5) * 2 * (maxSphereRadius )
    const randomValueZ = (Math.random() - 0.5) * 2 * (maxSphereRadius )
    const positionX = randomValueX
    const positionY = randomValueY
    const positionZ = randomValueZ

    const isOutsideMinSphere = Math.sqrt(Math.pow(positionX,2) + Math.pow(positionY,2) + Math.pow(positionZ,2)) >= minSphereRadius
    const isInsideMaxSphere = Math.sqrt(Math.pow(positionX,2) + Math.pow(positionY,2) + Math.pow(positionZ,2)) <= maxSphereRadius

    if(isInsideMaxSphere && isOutsideMinSphere){
        return new THREE.Vector3(positionX, positionY, randomValueZ)
    }
    else {
        return compute3DCoordinates(minSphereRadius, maxSphereRadius)
    }
}

// gui.add(configOptions, 'minSceneSphereRadius')
//     .min(0)
//     .max(100)
//     .step(0.01)
//     .onFinishChange(()=>{
//         deleteObjects(localObjectCount)
//         localObjectCount = configOptions.objectsCount
//         setupObjects(configOptions.objectsCount)
//     })

const maxSceneSphereRadiusHelper = gui.add(configOptions, 'maxSceneSphereRadius')
    .min(configOptions.minSceneSphereRadius)
    .max(50)
    .step(0.01)
    .name('Resize the universe')
    .onFinishChange(()=>{
        deleteObjects(localObjectCount)
        localObjectCount = configOptions.objectsCount
        setupObjects(configOptions.objectsCount)
    })

const objectsCountHelper = gui.add(configOptions, 'objectsCount')
    .min(0)
    .max(5000)
    .step(0.01)
    .name('More satellites?')
    .onFinishChange(()=>{
        deleteObjects(localObjectCount)
        localObjectCount = configOptions.objectsCount
        setupObjects(configOptions.objectsCount)
    })

const sceneBackgroundColorHelper = gui.addColor(configOptions, 'sceneBackgroundColor')
    .name('Favourite color?')
    .onFinishChange(()=>{
        
        configOptions.nameColor = invertColor(configOptions.sceneBackgroundColor)
        nameMaterial.color.set(configOptions.nameColor)
        sceneBackgroundColorHelper.updateDisplay()
        // nameColorHelper.updateDisplay()

        deleteObjects(localObjectCount)
        localObjectCount = configOptions.objectsCount
        setupObjects(configOptions.objectsCount)
    })

// const nameColorHelper = gui.addColor(configOptions, 'nameColor')
//     .name('My color?')
//     .onFinishChange(()=>{
        
//         configOptions.sceneBackgroundColor = invertColor(configOptions.nameColor)
//         nameMaterial.color.set(configOptions.nameColor)
//         sceneBackgroundColorHelper.updateDisplay()
//         nameColorHelper.updateDisplay()

//         deleteObjects(localObjectCount)
//         localObjectCount = configOptions.objectsCount
//         setupObjects(configOptions.objectsCount)
//     })

const setupObjects = function (objectsCount) {

    scene.background = new THREE.Color(configOptions.sceneBackgroundColor)
   
    for(let i = 0; i < objectsCount; i++)
    {    
        const randomNumber = Math.floor(Math.random() * 3) + 1;

        let geometry;
        let material;

        switch (randomNumber) {
        case 1:
            geometry = donutGeometry
            material = silverMaterial
            break;
        case 2:
            geometry = sphereGeometry
            material = nameMaterial
            break;
        case 3:
            geometry = squareGeometry
            material = wireframeMaterial
            break;
        default:
            geometry = donutGeometry
            break;
        }

        const object = new THREE.Mesh(geometry, material)

        const randomPosition = compute3DCoordinates(configOptions.minSceneSphereRadius, configOptions.maxSceneSphereRadius)

        if(randomPosition){
            object.position.x = randomPosition.x 
            object.position.y = randomPosition.y 
            object.position.z = randomPosition.z
    
            object.rotation.x = Math.random() * Math.PI
            object.rotation.y = Math.random() * Math.PI
    
            const scale = Math.min(Math.random(),0.6)
            object.scale.set(scale, scale, scale)
    
            objectsArray[i] = object
    
            scene.add(object)
        }
    }
}

const deleteObjects = function (objectsCount) {
    for(let i = 0; i < objectsCount; i++)
    {
         const object = objectsArray[i]
         scene.remove(object)
    }
    renderer.render(scene, camera)
}


gui.add({regenerateObjects: ()=>{
    configOptions.maxSceneSphereRadius = Math.ceil(
            Math.random()*
            2*(configOptions.maxSceneSphereRadius-configOptions.minSceneSphereRadius)
            +configOptions.minSceneSphereRadius
        )
    maxSceneSphereRadiusHelper.updateDisplay()

    configOptions.objectsCount = Math.floor(
        Math.random()*
        2*configOptions.objectsCount
        )
    objectsCountHelper.updateDisplay()

    configOptions.sceneBackgroundColor = randomColor()
    configOptions.nameColor = invertColor(configOptions.sceneBackgroundColor)
    nameMaterial.color.set(configOptions.nameColor)
    sceneBackgroundColorHelper.updateDisplay()
    // nameColorHelper.updateDisplay()

    deleteObjects(localObjectCount)
    setupObjects(localObjectCount)
}}, 'regenerateObjects').name('Big bang!')

setupObjects(configOptions.objectsCount)

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()